/* If you need to add @import statements, do so up here */
@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    /*Navbar*/
    .founder-quote {
        @apply relative text-gray-800 dark:text-slate-400;
    }
    .founder-quote::before {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTExNiIgaGVpZ2h0PSI1MDMiIHZpZXdCb3g9IjAgMCAxMTE2IDUwMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC4zIiBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzMxNF84OTc2KSI+CjxwYXRoIGQ9Ik0xMDAgMjAwQzEwMCAxNDQuNzcyIDE0NC43NzIgMTAwIDIwMCAxMDBIOTE2Qzk3MS4yMjggMTAwIDEwMTYgMTQ0Ljc3MiAxMDE2IDIwMFYzMDNDMTAxNiAzNTguMjI4IDk3MS4yMjggNDAzIDkxNiA0MDNIMjAwQzE0NC43NzIgNDAzIDEwMCAzNTguMjI4IDEwMCAzMDNWMjAwWiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzMxNF84OTc2KSIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2ZfMzE0Xzg5NzYiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMTE2IiBoZWlnaHQ9IjUwMyIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJzaGFwZSIvPgo8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSI1MCIgcmVzdWx0PSJlZmZlY3QxX2ZvcmVncm91bmRCbHVyXzMxNF84OTc2Ii8+CjwvZmlsdGVyPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMzE0Xzg5NzYiIHgxPSIxMTEiIHkxPSIyNjAuNSIgeDI9IjQzMy45NTYiIHkyPSItMzM0LjYxNyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjNDVDNEY5Ii8+CjxzdG9wIG9mZnNldD0iMC41NDg2MDEiIHN0b3AtY29sb3I9IiM4OTIwRkUiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRkYwQkU1Ii8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==");
        z-index: -1;
        @apply absolute top-0 lg:-top-32 right-0 lg:-right-32 ;
    }
    .founder-quote blockquote::before {
        content: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJjdXJyZW50Q29sb3IiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCI+PC9nPjxnIGlkPSJTVkdSZXBvX3RyYWNlckNhcnJpZXIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9nPjxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj48cGF0aCBkPSJNMy42OTEgNi4yOTJDNS4wOTQgNC43NzEgNy4yMTcgNCAxMCA0aDF2Mi44MTlsLS44MDQuMTYxYy0xLjM3LjI3NC0yLjMyMy44MTMtMi44MzMgMS42MDRBMi45MDIgMi45MDIgMCAwIDAgNi45MjUgMTBIMTBhMSAxIDAgMCAxIDEgMXY3YzAgMS4xMDMtLjg5NyAyLTIgMkgzYTEgMSAwIDAgMS0xLTF2LTVsLjAwMy0yLjkxOWMtLjAwOS0uMTExLS4xOTktMi43NDEgMS42ODgtNC43ODl6TTIwIDIwaC02YTEgMSAwIDAgMS0xLTF2LTVsLjAwMy0yLjkxOWMtLjAwOS0uMTExLS4xOTktMi43NDEgMS42ODgtNC43ODlDMTYuMDk0IDQuNzcxIDE4LjIxNyA0IDIxIDRoMXYyLjgxOWwtLjgwNC4xNjFjLTEuMzcuMjc0LTIuMzIzLjgxMy0yLjgzMyAxLjYwNEEyLjkwMiAyLjkwMiAwIDAgMCAxNy45MjUgMTBIMjFhMSAxIDAgMCAxIDEgMXY3YzAgMS4xMDMtLjg5NyAyLTIgMnoiPjwvcGF0aD48L2c+PC9zdmc+");
        @apply absolute -top-16 -left-8 w-14;
    }
    .founder-quote blockquote::after {
        content: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJjdXJyZW50Q29sb3IiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCI+PC9nPjxnIGlkPSJTVkdSZXBvX3RyYWNlckNhcnJpZXIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9nPjxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj48cGF0aCBkPSJNMjAuMzA5IDE3LjcwOEMyMi4xOTYgMTUuNjYgMjIuMDA2IDEzLjAzIDIyIDEzVjVhMSAxIDAgMCAwLTEtMWgtNmMtMS4xMDMgMC0yIC44OTctMiAydjdhMSAxIDAgMCAwIDEgMWgzLjA3OGEyLjg5IDIuODkgMCAwIDEtLjQyOSAxLjM5NmMtLjUwOC44MDEtMS40NjUgMS4zNDgtMi44NDYgMS42MjRsLS44MDMuMTZWMjBoMWMyLjc4MyAwIDQuOTA2LS43NzEgNi4zMDktMi4yOTJ6bS0xMS4wMDcgMEMxMS4xOSAxNS42NiAxMC45OTkgMTMuMDMgMTAuOTkzIDEzVjVhMSAxIDAgMCAwLTEtMWgtNmMtMS4xMDMgMC0yIC44OTctMiAydjdhMSAxIDAgMCAwIDEgMWgzLjA3OGEyLjg5IDIuODkgMCAwIDEtLjQyOSAxLjM5NmMtLjUwOC44MDEtMS40NjUgMS4zNDgtMi44NDYgMS42MjRsLS44MDMuMTZWMjBoMWMyLjc4MyAwIDQuOTA2LS43NzEgNi4zMDktMi4yOTJ6Ij48L3BhdGg+PC9nPjwvc3ZnPg==");
        @apply absolute -bottom-16 -right-8 w-14;
    }
    .navbar {
        @apply sticky z-40 w-full flex flex-row justify-between top-0 backdrop-blur flex-none transition-colors duration-200 bg-white/95 dark:bg-transparent border border-b border-gray-100 dark:border-slate-50/[0.06] shadow;
    }
    .nav-button {
        @apply inline-flex text-gray-800 dark:text-slate-400 text-lg font-bold block hover:bg-gray-100 hover:dark:bg-transparent hover:dark:text-blue-500 py-2 px-4 mx-2 rounded;
    }
    .shape>* {
        -webkit-transform: scale(2);
        transform: scale(2);
    }
    .shape-top {
        -webkit-transform-origin: top center;
        transform-origin: top center;
    }
    .shape-flip-x {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
    .logo-gradient-stop-1 {
        stop-color: #3688fc;
    }
    .logo-gradient-stop-2 {
        stop-color: #60a5fa;
    }
    .card {
        @apply mb-2 shadow rounded ring-1 ring-slate-900/5;
    }
    .card .card-footer {
        @apply py-2 px-4 bg-transparent border-gray-200 border-t;
    }
    .badge {
        @apply inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full;
    }
    .intro {
        @apply relative;
        transform: translate(0);
        transform-style: preserve-3d;
    }
    /* Tabs */
    .tabs {
        @apply flex flex-col md:flex-row items-center justify-between py-2 mb-4;
    }
    .tab {
        @apply flex flex-row items-center md:flex-col text-xs rounded-xl border border-slate-300 bg-white p-2 md:p-4 text-center mb-2 md:mb-0;
    }
    .tab.active {
        @apply bg-gradient-to-tr from-sky-200 to-fuchsia-100 font-bold border border-indigo-500 text-indigo-500;
    }
    .panels {
        @apply relative shadow-lg border border-indigo-500 bg-white rounded-xl;
    }
    .panels-shape {
        @apply w-3/4 absolute -top-40 right-0 lg:-right-64 rotate-180;
        z-index: 0;
    }
    .panels:after {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTExNiIgaGVpZ2h0PSI1MDMiIHZpZXdCb3g9IjAgMCAxMTE2IDUwMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC4zIiBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzMxNF84OTc2KSI+CjxwYXRoIGQ9Ik0xMDAgMjAwQzEwMCAxNDQuNzcyIDE0NC43NzIgMTAwIDIwMCAxMDBIOTE2Qzk3MS4yMjggMTAwIDEwMTYgMTQ0Ljc3MiAxMDE2IDIwMFYzMDNDMTAxNiAzNTguMjI4IDk3MS4yMjggNDAzIDkxNiA0MDNIMjAwQzE0NC43NzIgNDAzIDEwMCAzNTguMjI4IDEwMCAzMDNWMjAwWiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzMxNF84OTc2KSIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2ZfMzE0Xzg5NzYiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMTE2IiBoZWlnaHQ9IjUwMyIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJzaGFwZSIvPgo8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSI1MCIgcmVzdWx0PSJlZmZlY3QxX2ZvcmVncm91bmRCbHVyXzMxNF84OTc2Ii8+CjwvZmlsdGVyPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMzE0Xzg5NzYiIHgxPSIxMTEiIHkxPSIyNjAuNSIgeDI9IjQzMy45NTYiIHkyPSItMzM0LjYxNyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjNDVDNEY5Ii8+CjxzdG9wIG9mZnNldD0iMC41NDg2MDEiIHN0b3AtY29sb3I9IiM4OTIwRkUiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRkYwQkU1Ii8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==");
        z-index: -1;
        @apply absolute top-0 lg:-top-20 right-0 lg:-right-32;
    }
    .panel {
        @apply relative z-10 flex flex-row items-center;
    }
    .panel-copy {
        @apply flex flex-col flex-auto mr-4;
    }
    .panel-title {
        @apply text-2xl md:text-4xl leading-tight font-black mb-4;
    }
    .panel .tagline {
        @apply font-extrabold text-xl mb-2;
    }
    .highlight-list {
        @apply hidden md:flex flex-col w-2/5 flex-none;
    }
    .highlight-list ul { @apply w-full py-2 border border-slate-300 rounded-lg bg-white;}
    .highlight-list li {
        @apply inline-flex items-center text-lg font-semibold py-2 px-4 border-b border-slate-300 w-full;
    }
    .highlight-list li:last-child { @apply border-0; }

    /* Features */
    .features {
        @apply px-6 container mx-auto grid grid-cols-3 gap-4 lg:gap-8 mt-20 mb-16;
    }
    .features h3 {
        @apply text-2xl font-black mb-4;
    }

    .features p {
        @apply text-lg;
    }

    .feature {
        @apply relative border border-gray-200 bg-gray-100 rounded-xl col-span-3 lg:col-span-1 overflow-hidden lg:overflow-visible;
        transform: translate(0);
        transform-style: preserve-3d;
    }
    .feature-lg {
        @apply lg:col-span-2;
    }
    
    .feature-1:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 375px;
        background-image: url(/images/documents_2.svg);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        top: 0px;
        left: 0;
        z-index:1;
    }

    @media (max-width: 990px) {
        .feature-1:after {
            height: 202.5px;
            background-size: contain;
            top: -40px;
        }
    }

    @media (min-width: 991px) and(max-width: 1024px) {
        .feature-1:after {
            height: 270px;
            background-size: contain;
            top: 0;
        }
    }

    .feature-2:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 480px;
        background-image: url(/images/creative_process.svg);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        top: -115px;
        left: 0;
        z-index:1
    }

    @media (max-width: 990px) {
        .feature-2:after {
            height: 202.5px;
            background-size: contain;
            top: -60px;
        }
    }

    @media (min-width: 991px) and(max-width: 1024px) {
        .feature-2:after {
            height: 202.5px;
            background-size: contain;
            top: 30px;
        }
    }

    .feature-3:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 480px;
        background-image: url(/images/feature-1-bg.png);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        top: -145px;
        left: 0;
        z-index:1
    }

    .btn {
        @apply inline-flex items-center font-bold ease-in duration-100 py-3 px-7 rounded-full shadow-md transition-all cursor-pointer;
    }
    
    .cta-btn {
        @apply focus:outline-none focus:ring-2 focus:ring-opacity-75 text-white bg-gradient-to-r from-primary-500 to-indigo-500 hover:from-primary-600 hover:to-indigo-600;
    }

    @media (max-width: 990px) {
        .feature-3:after {
            height: 215.33333px;
            background-size: contain;
            top: -60px;
        }
    }

    @media (min-width: 991px) and(max-width: 1024px) {
        .feature-3:after {
            height: 270px;
            background-size: contain;
            top: -30px;
        }
    }
}

@layer utilities {
    .gradient-text {
        @apply bg-gradient-to-br from-sky-500 to-fuchsia-500 bg-clip-text text-transparent;
    }
    .light-gradient-bg {
        background: linear-gradient(212deg, #e0e7ff, #bae6fd, #fae8ff, #eff6ff);
        background-size: 800% 800%;
    
        -webkit-animation: GradientAnimation 22s ease infinite;
        -moz-animation: GradientAnimation 22s ease infinite;
        animation: GradientAnimation 22s ease infinite;
    }
    
    @-webkit-keyframes GradientAnimation {
        0%{background-position:83% 0%}
        50%{background-position:18% 100%}
        100%{background-position:83% 0%}
    }
    @-moz-keyframes GradientAnimation {
        0%{background-position:83% 0%}
        50%{background-position:18% 100%}
        100%{background-position:83% 0%}
    }
    @keyframes GradientAnimation {
        0%{background-position:83% 0%}
        50%{background-position:18% 100%}
        100%{background-position:83% 0%}
    }

    @media (min-width: 1024px) {
        .fade-in {
            opacity: 0;
            -webkit-animation: fadeIn ease-in 1;
            animation: fadeIn ease-in 1;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            -webkit-animation-duration: .5s;
            animation-duration:.5s
        }

        .fade-in-first {
            -webkit-animation-delay: .1s;
            animation-delay:.1s
        }

        .fade-in-second {
            -webkit-animation-delay: .5s;
            animation-delay:.5s
        }

        .fade-in-third {
            -webkit-animation-delay: .75s;
            animation-delay:.75s
        }

        .fade-in-fourth {
            -webkit-animation-delay: 1s;
            animation-delay:1s
        }
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity:0
        }

        to {
            opacity:1
        }
    }

    @keyframes fadeIn {
        0% {
            opacity:0
        }

        to {
            opacity:1
        }
    }

    .box {
        position: relative;
        transform-style: preserve-3d;
        border-radius: .75rem;
    }
    .conic-gradient-bg {
        background: conic-gradient(from 90deg at 80% -50%, #0ea5e9, #3b82f6, #6366f1, #d946ef, #8b5cf6, #a855f7, #0ea5e9, #3b82f6);
    }
    .gradient-shadow::before {
        content: "";
        position: absolute; 
        inset: 0px;
        background: conic-gradient(from 90deg at 40% -25%, #0ea5e9, #3b82f6, #6366f1, #d946ef, #8b5cf6, #a855f7);
        filter: blur(10px);
        transform: translate3d(0px,0px,-1px);
        border-radius: inherit;
        pointer-events: none;
    }
}